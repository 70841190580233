import Vue from 'vue';
import '@/config/plugins';
import '@/config/styles';
import '@/config/components';
import { onMounted } from '@vue/composition-api';
import vuetify from '@/config/plugins/vuetify';
import { useHeader } from '@/app/use/useLayouts';

export default new Vue({
  el: '#index',
  delimiters: ['[{', '}]'],
  vuetify,
  setup() {
    // onMounted(() => {});
    return {
      ...useHeader(),
    };
  },
});
